import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Label, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, Progress } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";

import { createDeepClone } from "../utils/index";

import ConfirmModal from "./ConfirmModal";
import ErrorModal from "./ErrorModal";
import PdfModal from "./PdfModal";
import WordModal from "./WordModal";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";
import MyNumberRenderer from "./MyNumberRenderer";
import { api, getFileTitle, getFileExtension } from "../utils/index";

import { useDispatch, useSelector } from "react-redux";
import * as documentActions from "../actions/DocumentActions";
import * as commonActions from "../actions/CommonActions";

const ServiceDocumentGrid = (props) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [showMultipleFileMessageModal, setShowMultipleFileMessageModal] = useState(false);
    const [multipleFileMessage, setMultipleFileMessage] = useState("");
    const [allowMultiple, setAllowMultiple] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentUploadProgress, setCurrentUploadProgress] = useState(false);
    
    const [deleteId, setDeleteId] = React.useState(0);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [showWordModal, setShowWordModal] = useState(false);

    const downloading = useSelector((state) => state.document.downloading);
    const documentTypeList = useSelector((state) => state.login.data.documentTypeList);

    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [showValidDateMessage, setShowValidDateMessage] = React.useState(false);
    const [showIssueDateMessage, setShowIssueDateMessage] = React.useState(false);
    const [showCertificateTypeMessage, setShowCertificateTypeMessage] = React.useState(false);
    const [showFOBMessage, setShowFOBMessage] = React.useState(false);
    const [showCurrencyMessage, setShowCurrencyMessage] = React.useState(false);
    const [showValidTestReportMessage, setShowValidTestReportMessage] = React.useState(false);
    const [fboState, setFBOState] = React.useState(null);
    const [documentList, setDocumentList] = React.useState([]);
    const [gridCollapsed, setGridCollapsed] = React.useState(props.collapsed === "false" ? false : true);

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
        
            var data = await documentActions.loadServiceDocument(props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);
            }
    
            setLoading(false);
        }

        fetchData();
    }, [props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct]);

    React.useEffect(() => {
        if(multipleFileMessage && multipleFileMessage.length > 0) {
            setShowMultipleFileMessageModal(true);
        }
    }, [multipleFileMessage]);

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const getDateWithoutTimezone = (value) => {
        if(value) {
            const tzoffset = value.getTimezoneOffset() * 60000;
            const withoutTimezone = new Date(value.valueOf() - tzoffset).toISOString().slice(0, -1);
            return withoutTimezone;
        }
        return "";
    }

    const onDocumentChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            if(name === "fob") {
                value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            }

            if(name === "validDate") {
                copy_fboState[name] = getDateWithoutTimezone(value);
            }
            else if(name === "issueDate") {
                copy_fboState[name] = getDateWithoutTimezone(value);
            }
            else {
                copy_fboState[name] = value;
            }
        }
        else if(value === false) {
            copy_fboState[name] = false;
        }
        else {
            copy_fboState[name] = null;
        }
        
        setFBOState(copy_fboState);
        setShowValidDateMessage(false);
        setShowIssueDateMessage(false);
        setShowCertificateTypeMessage(false);
        setShowFOBMessage(false);
        setShowCurrencyMessage(false);
        setShowValidTestReportMessage(false);
    }

    const onDocumentReset = (documentType) => {
        var copy_fboState = createDeepClone(fboState);

        copy_fboState.documentType = documentType;
        copy_fboState.validDate = null;
        copy_fboState.issueDate = null;
        copy_fboState.certificateType = null
        copy_fboState.fob = null
        copy_fboState.fobCurrency = null
        copy_fboState.isValidTestReport = null
                
        setFBOState(copy_fboState);
        setShowValidDateMessage(false);
        setShowIssueDateMessage(false);
        setShowCertificateTypeMessage(false);
        setShowFOBMessage(false);
        setShowCurrencyMessage(false);
        setShowValidTestReportMessage(false);
    }

    const onFileChange = (event) => {
        if(event.target.files.length > 0) {
            var files = [...event.target.files];
            var copy_selectedFiles = createDeepClone(selectedFiles);
            
            if(props.serviceId === 5 && fboState.documentType === "TR") {
                files.map(function(item, index) {
                    copy_selectedFiles.push({
                        file: item, 
                        name: item.name, 
                        size: item.size, 
                        title: "", 
                        titleValid: false,
                        uploaded: false,
                        progress: 0, 
                        error: ""
                    });
                });
            }
            else {
                files.map(function(item, index) {
                    copy_selectedFiles.push({
                        file: item, 
                        name: item.name, 
                        size: item.size, 
                        title: getFileTitle(item), 
                        titleValid: true,
                        uploaded: false,
                        progress: 0, 
                        error: ""
                    });
                });
            }

            setSelectedFiles(copy_selectedFiles);
        }
    }

    const onFileTitleChange = (index, value) => {
        var copy_selectedFiles = createDeepClone(selectedFiles);
        if(value) {
            copy_selectedFiles[index].title = value;
            copy_selectedFiles[index].titleValid = true;
        }
        else {
            copy_selectedFiles[index].title = null;
            copy_selectedFiles[index].titleValid = false;
        }
        
        setSelectedFiles(copy_selectedFiles);
    }

    const onFileDelete = (index) => {
        var copy_selectedFiles = createDeepClone(selectedFiles);
        copy_selectedFiles.splice(index, 1);
        setSelectedFiles(copy_selectedFiles);
    }

    const showModalDialog = async (item) => {
        onDocumentReset(item.code);

        setSelectedFiles([]);
        setAllowMultiple(item.allowMultiple);
        setMultipleFileMessage("");

        if(item.allowMultiple) {
            setShowModal(true);
        }
        else{
            setLoading(true);
            var data = await documentActions.loadServiceDocument(props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct);
            setLoading(false);

            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                var canUpload = true;
                var documentName = "document";

                data.list.forEach(document => {
                    if(document.code === item.code && document.documentList && document.documentList.length > 0) {
                        canUpload = false;
                        documentName = document.name;
                    }
                });

                if(canUpload) {
                    setShowModal(true);
                }
                else {
                    setMultipleFileMessage(documentName);
                }
            }
        }        
    }

    const hideModalDialog = () => {
        setShowModal(false);
    }

    const uploadFile = async (file, index) => {
        setSaving(true);
        setCurrentUploadProgress(0);
        
        var uploadSuccess = true;
        var uploadError = "";
        var tempFileName = "";
        var chunkSize = 1048576 * 5;
        var chunkCounter = 0;
        var chunkTotal = file.size % chunkSize == 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;
        var chunkStart = 0;
        var chunkEnd = chunkSize;

        selectedFiles[index].uploaded = false;
        selectedFiles[index].progress = 0;
        selectedFiles[index].error = "";

        while (chunkCounter <= chunkTotal && uploadSuccess === true) {
            var chunk = file.file.slice(chunkStart, chunkEnd);
            
            const uploadFormData = new FormData();
            uploadFormData.append("tempFileName", tempFileName);
            uploadFormData.append("file", chunk, file.name);

            var data = await documentActions.uploadFileChunk(uploadFormData);
            if(data.success) {
                tempFileName = data.tempFileName;
                uploadSuccess = true;
                uploadError = "";

                var percentage = Math.round((chunkCounter / chunkTotal) * 100);
                selectedFiles[index].progress = percentage;

                setCurrentUploadProgress(percentage);
            }
            else {
                uploadSuccess = false;
                uploadError = data.error;
            }

            chunkCounter += 1;
            chunkStart = chunkEnd;
            chunkEnd += chunkSize;
        }

        if(uploadSuccess) {
            const formData = new FormData();
                            
            formData.append("serviceId", props.serviceId);
            formData.append("isProduct", props.isProduct);
            formData.append("document", JSON.stringify(fboState));
            formData.append("title", file.title ? file.title : file.name);
            formData.append("originalFileName", file.name);
            formData.append("tempFileName", tempFileName);

            var data = await documentActions.saveServiceDocument(formData);
            if(data.error) {
                uploadSuccess = false;
                uploadError = data.error;
            }
            else {
                uploadSuccess = true;
                uploadError = "";

                setDocumentList(data.list);

                if(props.onChange) {
                    props.onChange(props.isProduct);
                }
            }
        }

        selectedFiles[index].uploaded = uploadSuccess;
        selectedFiles[index].error = uploadError;
        
        setSelectedFiles(selectedFiles);
        setSaving(false);
    }

    const onSaveDocumentClick = async () => {
        if(selectedFiles && selectedFiles.length > 0) {
            var valid = true;
            
            if(fboState.documentType === "FA" && !fboState.validDate) {
                valid = false;
                setShowValidDateMessage(true);
            }
            else if(props.serviceId === 5 && fboState.documentType === "CR" && !fboState.certificateType) {
                valid = false;
                setShowCertificateTypeMessage(true);
            }
            else if(props.serviceId === 5 && fboState.documentType === "CI" && !fboState.fob) {
                valid = false;
                setShowFOBMessage(true);
            }
            else if(props.serviceId === 5 && fboState.documentType === "CI" && !fboState.fobCurrency) {
                valid = false;
                setShowCurrencyMessage(true);
            }
            else if(props.serviceId === 7 && fboState.documentType === "TR" && !fboState.issueDate) {
                valid = false;
                setShowIssueDateMessage(true);
            }
            else if(props.serviceId !== 7 && fboState.documentType === "TR" && !fboState.isValidTestReport) {
                valid = false;
                setShowValidTestReportMessage(true);
            }
            else {
                selectedFiles.map(function(item, index){
                    if(!item.titleValid) {
                        valid = false;
                    }
                });
            }

            if(valid) {
                await SaveDocument();
            }
        }
        else {
            dispatch(commonActions.setErrorMessage("No file is selected."));
        }
    }

    const SaveDocument = async () => {
        var allFilesUploaded = true;
        var closeDialog = true;

        for(var index in selectedFiles) {
            if(selectedFiles[index].uploaded === false) {
                allFilesUploaded = false;
                await uploadFile(selectedFiles[index], index);
            }
        }

        for(var index in selectedFiles) {
            if(selectedFiles[index].uploaded === false) {
                closeDialog = false;
            }
        }

        if(closeDialog) {
            hideModalDialog();
            setSelectedFiles([]);

            if(props.isProduct) {
                dispatch(commonActions.setSuccessMessage("Product Document(s) Added Successfully!"))
            }
            else {
                dispatch(commonActions.setSuccessMessage("Document(s) Added Successfully!"))
            }
        }
        else if(allFilesUploaded) {
            dispatch(commonActions.setErrorMessage("All Selected files are uploaded."));
        }
    }

    const onDeleteClick = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const onDeleteConfirm = async () => {
        setShowDeleteModal(false);

        if(deleteId > 0) {
            setLoading(true);
        
            var data = await documentActions.deleteServiceDocument(deleteId, props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);

                if(props.onChange) {
                    props.onChange(props.isProduct);
                }

                if(props.isProduct) {
                    dispatch(commonActions.setSuccessMessage("Product Document Delete Successfully!"))
                }
                else {
                    dispatch(commonActions.setSuccessMessage("Document Delete Successfully!"))
                }
            }
    
            setLoading(false);
        }
    }

    const onDownloadServiceTemplate = (templateName) => {
        dispatch(documentActions.downloadServiceTemplate(templateName));
    };

    const onDownloadServiceDocument = (id, documentName) => {
        dispatch(documentActions.downloadServiceDocument(id, documentName));
    };

    const showPhotoModalDialog = (item) => {
        setSelectedItem(item);
        setShowPhotoModal(true);
    }

    const hidePhotoModalDialog = () => {
        setShowPhotoModal(false);
    }

    const showPdfModalDialog = (item) => {
        setSelectedItem(item);
        setShowPdfModal(true);
    }

    const hidePdfModalDialog = () => {
        setShowPdfModal(false);
    }

    const showWordModalDialog = (item) => {
        setSelectedItem(item);
        setShowWordModal(true);
    }

    const hideWordModalDialog = () => {
        setShowWordModal(false);
    }

    const isPhotoFile = (fileName) => {
        var photoList = ["jpeg", "jpg", "bmp", "gif", "png", "tiff", "webp", "eps"];
        var extension = getFileExtension(fileName);

        if(photoList.indexOf(extension.toLowerCase()) !== -1) {
            return true;
        }

        return false;
    }

    const isPdfFile = (fileName) => {
        var pdfList = ["pdf"];
        var extension = getFileExtension(fileName);

        if(pdfList.indexOf(extension.toLowerCase()) !== -1) {
            return true;
        }

        return false;
    }

    const isWordFile = (fileName) => {
        var wordList = ["doc", "docx", "xls", "xlsx"];
        var extension = getFileExtension(fileName);

        if(wordList.indexOf(extension.toLowerCase()) !== -1) {
            return true;
        }

        return false;
    }

    return (
        <Fragment>
            <Card className={`${props.isProduct ? "mb-3" : "mb-3"}`}>
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading || downloading} />
                    <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setGridCollapsed(!gridCollapsed)}>
                        {gridCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                        {!gridCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                        {props.isProduct ? "Product " : ""}Attachments / Documents ({gridCollapsed ? "Expand" : "Collapse"})
                    </h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <Collapse isOpen={!gridCollapsed} className="collapse">
                        {documentList.map(function(item, index){
                            return(
                                <Card className={`${props.isProduct ? "card-light" : "card-light"}`} key={index}>
                                    <CardHeader className="d-flex">
                                        <div className="flex-grow-1">
                                            <h4 className="card-title mb-2">{item.name}</h4>
                                            <h6 className="card-subtitle font-14 text-muted">{item.instruction}</h6>
                                        </div>
                                        {item.templateName &&
                                            <button type="button" className="btn btn-secondary btn-icon float-end rounded-pill ms-1" title="Download Template"
                                                onClick={() => onDownloadServiceTemplate(item.templateName)}
                                            >
                                                <i className="ri-download-line" />
                                            </button>
                                        }
                                        {props.canAddDocument && item.code !== "CR" &&
                                        <button type="button" className="btn btn-primary btn-icon float-end rounded-pill ms-1" title="Upload Document"
                                            onClick={() => showModalDialog(item)}
                                        >
                                            <i className="ri-upload-line" />
                                        </button>
                                        }
                                        {props.canAddCertificate && item.code === "CR" &&
                                        <button type="button" className="btn btn-primary btn-icon float-end rounded-pill ms-1" title="Upload Document"
                                            onClick={() => showModalDialog(item)}
                                        >
                                            <i className="ri-upload-line" />
                                        </button>
                                        }
                                    </CardHeader>
                                    <CardBody className="card-text">
                                        {item.parentDocumentList.length > 0 &&
                                            <Fragment>
                                                <Row className="gy-2 pt-1"><h6>Container Documents</h6></Row>
                                                {item.parentDocumentList.map(function(document, index){
                                                    return(
                                                        <Row className="gy-2 pt-1 font-14" key={index}>
                                                            <Col xxl={3} md={6}>{document.title}</Col>
                                                            <Col xxl={3} md={6}>{document.name}</Col>
                                                            <Col xxl={3} md={6}><MyDateRenderer value={document.dateAdded} /></Col>
                                                            <Col xxl={3} md={6}>
                                                                <button type="button" className="btn btn-primary btn-sm me-1" title="Download Document"
                                                                    onClick={() => onDownloadServiceDocument(document.id, document.name)}
                                                                >
                                                                    <i className="ri-download-line" /> Download
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row className="gy-2 pt-3"><hr /></Row>
                                            </Fragment>
                                        }
                                        {item.documentList.length == 0 && <div className="font-14">No Document Uploaded</div>}
                                        {item.documentList.map(function(document, index){
                                            return(
                                                <Row className="gy-2 pt-1 font-14" key={index}>
                                                    <Col xxl={3} md={6}>
                                                        {document.title}
                                                        {document.validDate && <Fragment><br /><span className="badge bg-danger"><MyDateRenderer value={document.validDate} format={"mm/dd/yyyy"} /></span></Fragment>}
                                                        {document.issueDate && <Fragment><br /><span className="badge bg-danger"><MyDateRenderer value={document.issueDate} format={"mm/dd/yyyy"} /></span></Fragment>}
                                                        {document.certificateType && <Fragment><br /><span className="badge bg-danger">{document.certificateType}</span></Fragment>}
                                                        {document.isValidTestReport && <Fragment><br /><span className="badge bg-danger">Valid: {document.isValidTestReport}</span></Fragment>}
                                                        {document.fob && <Fragment><br /><span className="badge bg-danger"><MyNumberRenderer value={document.fob} /> {document.fobCurrency}</span></Fragment>}
                                                    </Col>
                                                    <Col xxl={3} md={6}>{document.name}</Col>
                                                    <Col xxl={3} md={6}><MyDateRenderer value={document.dateAdded} /></Col>
                                                    <Col xxl={3} md={6}>
                                                        <button type="button" className="btn btn-primary btn-sm me-1" title="Download Document"
                                                            onClick={() => onDownloadServiceDocument(document.id, document.name)}
                                                        >
                                                            <i className="ri-download-line" /> Download
                                                        </button>
                                                        {isPhotoFile(document.name) && 
                                                            <button type="button" className="btn btn-primary btn-sm me-1" title="View Document"
                                                                onClick={() => showPhotoModalDialog(document)}
                                                            >
                                                                <i className="ri-fullscreen-line" /> View
                                                            </button>
                                                        }
                                                        {isPdfFile(document.name) && 
                                                            <button type="button" className="btn btn-primary btn-sm me-1" title="View Document"
                                                                onClick={() => showPdfModalDialog(document)}
                                                            >
                                                                <i className="ri-fullscreen-line" /> View
                                                            </button>
                                                        }
                                                        {isWordFile(document.name) && 
                                                            <button type="button" className="btn btn-primary btn-sm me-1" title="View Document"
                                                                onClick={() => showWordModalDialog(document)}
                                                            >
                                                                <i className="ri-fullscreen-line" /> View
                                                            </button>
                                                        }
                                                        {props.canDeleteDocument && item.code !== "CR" &&
                                                        <button type="button" className="btn btn-danger btn-sm me-1" title="Delete Document"
                                                            onClick={() => onDeleteClick(document.id)}
                                                        >
                                                            <i className="ri-delete-bin-2-line" /> Delete
                                                        </button>
                                                        }
                                                        {props.canDeleteCertificate && item.code === "CR" &&
                                                        <button type="button" className="btn btn-danger btn-sm me-1" title="Delete Document"
                                                            onClick={() => onDeleteClick(document.id)}
                                                        >
                                                            <i className="ri-delete-bin-2-line" /> Delete
                                                        </button>
                                                        }
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </CardBody>
                                </Card>
                            );
                        })}
                    </Collapse>
                </CardBody>
            </Card>

            <Modal tabIndex="-1" size="xl" isOpen={showModal} toggle={hideModalDialog} centered={true}>
                <ModalHeader>
                    Upload Documents
                </ModalHeader>
                <ModalBody>
                    <CardLoader loading={saving} />
                    <Row className="gy-2">
                        <Col xxl={12} md={12}>
                            <label className="form-label">Document Type</label>
                            <select className="form-select" disabled={true}
                                value={(fboState && fboState.documentType) || ""}
                                onChange={e => onDocumentChange("documentType", e.target.value)}
                            >
                                <option value={""}>...</option>
                                {documentTypeList && documentTypeList.map(function(item, index){
                                    return (
                                        <option key={index} value={item.code}>{item.description}</option>
                                    );
                                })}
                            </select>
                        </Col>
                        {fboState && fboState.documentType === "FA" &&
                        <Col xxl={12} md={12}>
                            <label className="form-label">Expiry Date</label>
                            <Flatpickr className={`form-control ${showValidDateMessage ? "is-invalid" : ""}`}
                                value={(fboState && getFormattedDate(fboState.validDate)) || ""}
                                onChange={([date]) => onDocumentChange("validDate", date)}
                                options={{
                                    dateFormat: "m/d/Y"
                                }}
                            />
                        </Col>
                        }
                        {fboState && props.serviceId === 5 && fboState.documentType === "CR" &&
                        <Col xxl={12} md={12}>
                            <label className="form-label">Certificate Type<span className="required">*</span></label>
                            <select className={`form-select ${showCertificateTypeMessage ? "is-invalid" : ""}`}
                                value={(fboState && fboState.certificateType) || ""}
                                onChange={e => onDocumentChange("certificateType", e.target.value)}
                            >
                                <option value={""}>...</option>
                                <option value={"SCOC"}>SCOC</option>
                                <option value={"NSCOC"}>NSCOC</option>
                            </select>
                        </Col>
                        }
                        {fboState && props.serviceId === 5 && fboState.documentType === "CI" &&
                        <Fragment>
                            <Col xxl={2} md={6}>
                                <Label className="form-label">FOB Value</Label>
                                <input type="text" className={`form-control ${showFOBMessage ? "is-invalid" : ""}`}
                                    value={(fboState && fboState.fob) || ""}
                                    onChange={e => onDocumentChange("fob", e.target.value)}
                                />
                            </Col>
                            <Col xxl={2} md={6}>
                                <Label className="form-label">Currency</Label>
                                <select className={`form-select ${showCurrencyMessage ? "is-invalid" : ""}`}
                                    value={(fboState && fboState.fobCurrency) || ""}
                                    onChange={e => onDocumentChange("fobCurrency", e.target.value)}
                                >
                                    <option value={""}>...</option>
                                    <option value="AED">UAE Dirham</option>
                                    <option value="CNY">China Yuan</option>
                                    <option value="EUR">Euro</option>
                                    <option value="USD">USA Dollar</option>
                                    <option value="LKR">Sri Lanka Rupee</option>
                                    <option value="EGP">Egypt Pound</option>
                                    <option value="SAR">Saudi Riyal</option>
                                    <option value="INR">India Rupee</option>
                                </select>
                            </Col>
                        </Fragment>
                        }
                        {fboState && props.serviceId === 7 && fboState.documentType === "TR" &&
                        <Col xxl={12} md={12}>
                            <label className="form-label">Report Date</label>
                            <Flatpickr className={`form-control ${showIssueDateMessage ? "is-invalid" : ""}`}
                                value={(fboState && getFormattedDate(fboState.issueDate)) || ""}
                                onChange={([date]) => onDocumentChange("issueDate", date)}
                                options={{
                                    dateFormat: "m/d/Y",
                                    maxDate: "today"
                                }}
                            />
                        </Col>
                        }
                        {fboState && props.serviceId !== 7 && fboState.documentType === "TR" &&
                        <Col xxl={4} md={6} style={{border: (showValidTestReportMessage ? "1px solid red" : "")}}>
                            <Label className="form-label">Is Valid test report?<span className="required">*</span></Label>
                            <div className="form-check pt-2">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-0 me-5">
                                        <input type="radio" className="form-check-input"
                                            checked={(fboState && fboState.isValidTestReport === "Yes")}
                                            onChange={e => onDocumentChange("isValidTestReport", "Yes")}
                                        /> Yes
                                    </div>
                                    <div className="flex-grow-1">
                                        <input type="radio" className="form-check-input"
                                            checked={(fboState && fboState.isValidTestReport === "No")}
                                            onChange={e => onDocumentChange("isValidTestReport", "No")}
                                        /> No
                                    </div>
                                </div>
                            </div>
                        </Col>
                        }
                        <Col xxl={12} md={12}>
                            <label className="btn btn-primary btn-label mb-0 float-end">
                                <i className="ri-check-double-line label-icon align-bottom"></i> Choose File(s)
                                <input className="d-none" type="file" onChange={onFileChange} multiple={allowMultiple} />
                            </label>
                        </Col>
                        <Col xxl={12} md={12}>
                            <Table className="table-hover table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>Title</th>
                                        <th>File Name</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFiles.length === 0 && <tr><td colSpan={3}>No file(s) Selected</td></tr>}
                                    {selectedFiles.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>
                                                    <div className="col-sm-12">
                                                        <input className={`form-control ${item.titleValid ? "" : "is-invalid"}`} readOnly={item.uploaded}
                                                            placeholder={(props.serviceId === 5 && fboState.documentType === "TR") ? "Add Product Name here..." : "Add Title..."}
                                                            value={item.title}
                                                            onChange={e => onFileTitleChange(index, e.target.value)}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{verticalAlign:"middle"}}>
                                                    <div className="col-sm-12">
                                                        <strong>{item.name}</strong>
                                                    </div>
                                                    {item.error && <Progress color="danger" style={{height:"25px"}} value={100}> {item.error} </Progress>}
                                                    {!item.error && <Progress color="primary" style={{height:"25px"}} value={item.uploaded ? 100 : item.progress}> {item.uploaded ? 100 : item.progress}% </Progress>}
                                                </td>
                                                <td className="text-center">
                                                    {!item.uploaded &&
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill ms-1" title="Remove Photo"
                                                        onClick={() => onFileDelete(index)}
                                                    >
                                                        <i className="ri-delete-bin-2-line"></i>
                                                    </button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-label" onClick={onSaveDocumentClick} disabled={loading || saving}>
                        <i className="ri-save-line label-icon align-bottom"></i> Save Document(s)
                    </button>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hideModalDialog} disabled={loading || saving}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal tabIndex="-1" size="xl" isOpen={showPhotoModal} toggle={hidePhotoModalDialog} centered={true}>
                <ModalHeader>{selectedItem && selectedItem.title}</ModalHeader>
                <ModalBody>
                    {selectedItem && <img style={{maxWidth:"800px", maxHeight: "600px"}} src={`${api}Document/DownloadDocument?id=${selectedItem.id}`} alt="Not Found" />}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hidePhotoModalDialog}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <PdfModal item={selectedItem} show={showPdfModal} onCloseClick={hidePdfModalDialog} />
            <WordModal item={selectedItem} show={showWordModal} onCloseClick={hideWordModalDialog} />

            <ConfirmModal message={"Are you sure you want to delete the Document?"} show={showDeleteModal} onCloseClick={() => setShowDeleteModal(false)} onYesClick={onDeleteConfirm} />
            <ErrorModal message={`A document is already uploaded in \"${multipleFileMessage || "this"}\" section for this ticket. Only one document is allowed in \"${multipleFileMessage || "this"}\" section.`} show={showMultipleFileMessageModal} onCloseClick={() => setShowMultipleFileMessageModal(false)} />
        </Fragment>
    );
};

export default ServiceDocumentGrid;