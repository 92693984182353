//Search
export const TICKET_SEARCH_START_LOADING = "TICKET_SEARCH_START_LOADING";
export const TICKET_SEARCH_END_LOADING = "TICKET_SEARCH_END_LOADING";

export const TICKET_NOTIFY_START_LOADING = "TICKET_NOTIFY_START_LOADING";
export const TICKET_NOTIFY_END_LOADING = "TICKET_NOTIFY_END_LOADING";

//Search Loaded
export const TICKET_SEARCH_LOADED = "TICKET_SEARCH_LOADED";
export const TICKET_NOTIFY_LOADED = "TICKET_NOTIFY_LOADED";
export const TICKET_SEARCH_REVW_LOADED = "TICKET_SEARCH_REVW_LOADED";
export const TICKET_SEARCH_SCOC_LOADED = "TICKET_SEARCH_SCOC_LOADED";
export const TICKET_SEARCH_PCOC_LOADED = "TICKET_SEARCH_PCOC_LOADED";
export const TICKET_SEARCH_FA_LOADED = "TICKET_SEARCH_FA_LOADED";
export const TICKET_SEARCH_SOC_LOADED = "TICKET_SEARCH_SOC_LOADED";
export const TICKET_SEARCH_INS_LOADED = "TICKET_SEARCH_INS_LOADED";
export const TICKET_SEARCH_SAMP_LOADED = "TICKET_SEARCH_SAMP_LOADED";

//Set Filter
export const SET_TICKET_FILTER = "SET_TICKET_FILTER";
export const SET_TICKET_NOTIFY_FILTER = "SET_TICKET_NOTIFY_FILTER";
export const SET_TICKET_REVW_FILTER = "SET_TICKET_REVW_FILTER";
export const SET_TICKET_SCOC_FILTER = "SET_TICKET_SCOC_FILTER";
export const SET_TICKET_PCOC_FILTER = "SET_TICKET_PCOC_FILTER";
export const SET_TICKET_FA_FILTER = "SET_TICKET_FA_FILTER";
export const SET_TICKET_SOC_FILTER = "SET_TICKET_SOC_FILTER";
export const SET_TICKET_INS_FILTER = "SET_TICKET_INS_FILTER";
export const SET_TICKET_SAMP_FILTER = "SET_TICKET_SAMP_FILTER";

//Tags
export const TICKET_TAG_START_LOADING = "TICKET_TAG_START_LOADING";
export const TICKET_TAG_END_LOADING = "TICKET_TAG_END_LOADING";
export const TICKET_TAG_LOADED = "TICKET_TAG_LOADED";

//Edit
export const TICKET_START_LOADING = "TICKET_START_LOADING";
export const TICKET_END_LOADING = "TICKET_END_LOADING";
export const TICKET_LOADED = "TICKET_LOADED";

//Items
export const TICKET_ITEM_START_LOADING = "TICKET_ITEM_START_LOADING";
export const TICKET_ITEM_END_LOADING = "TICKET_ITEM_END_LOADING";
export const TICKET_ITEM_LOADED = "TICKET_ITEM_LOADED";

//Products
export const TICKET_PRODUCT_START_LOADING = "TICKET_PRODUCT_START_LOADING";
export const TICKET_PRODUCT_END_LOADING = "TICKET_PRODUCT_END_LOADING";
export const TICKET_PRODUCT_LOADED = "TICKET_PRODUCT_LOADED";

//Linked Tickets
export const LINKED_TICKET_START_LOADING = "LINKED_TICKET_START_LOADING";
export const LINKED_TICKET_END_LOADING = "LINKED_TICKET_END_LOADING";
export const LINKED_TICKET_LOADED = "LINKED_TICKET_LOADED";

//Related Tickets
export const RELATED_TICKET_START_LOADING = "RELATED_TICKET_START_LOADING";
export const RELATED_TICKET_END_LOADING = "RELATED_TICKET_END_LOADING";
export const RELATED_TICKET_LOADED = "RELATED_TICKET_LOADED";

//Ticket Users
export const TICKET_USER_START_LOADING = "TICKET_USER_START_LOADING";
export const TICKET_USER_END_LOADING = "TICKET_USER_END_LOADING";
export const TICKET_USER_LOADED = "TICKET_USER_LOADED";

//Invoice
export const TICKET_INVOICE_START_LOADING = "TICKET_INVOICE_START_LOADING";
export const TICKET_INVOICE_END_LOADING = "TICKET_INVOICE_END_LOADING";
export const TICKET_INVOICE_LOADED = "TICKET_INVOICE_LOADED";

//Invoice Contract
export const TICKET_INVOICE_CONTRACT_START_LOADING = "TICKET_INVOICE_CONTRACT_START_LOADING";
export const TICKET_INVOICE_CONTRACT_END_LOADING = "TICKET_INVOICE_CONTRACT_END_LOADING";
export const TICKET_INVOICE_CONTRACT_LOADED = "TICKET_INVOICE_CONTRACT_LOADED";

//Invoice Product
export const TICKET_INVOICE_PRODUCT_START_LOADING = "TICKET_INVOICE_PRODUCT_START_LOADING";
export const TICKET_INVOICE_PRODUCT_END_LOADING = "TICKET_INVOICE_PRODUCT_END_LOADING";
export const TICKET_INVOICE_PRODUCT_LOADED = "TICKET_INVOICE_PRODUCT_LOADED";

//Ticket Form
export const TICKET_FORM_START_LOADING = "TICKET_FORM_START_LOADING";
export const TICKET_FORM_END_LOADING = "TICKET_FORM_END_LOADING";
export const TICKET_FORM_LOADED = "TICKET_FORM_LOADED";
