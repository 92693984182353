import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import PaginationComponent from "../components/PaginationComponent";
import MySearchOption from "../components/MySearchOption";
import MyDateRenderer from "../components/MyDateRenderer";
import ComplianceLabel from "../components/ComplianceLabel";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";

const TicketSearchScreenINS  = () => {
    const dispatch = useDispatch();
        
    const loading = useSelector((state) => state.ticket.loading);
    const list = useSelector((state) => state.ticket.listINS);
    const filter = useSelector((state) => state.ticket.filterINS);
    const user = useSelector((state) => state.login.user);

    const doSearch = () => {
        var copy_filter = createDeepClone(filter);
        
        if(copy_filter.pageIndex == 1) {
            dispatch(ticketActions.loadTicketSearch(filter, "INS"));
        }
        else {
            copy_filter.pageIndex = 1;
            dispatch(ticketActions.setTicketFilter(copy_filter, "INS"));
        }
    }

    React.useEffect(() => {
		dispatch(ticketActions.loadTicketSearch(filter, "INS"));
    }, [filter.pageIndex]);

    const onFilterChange = (name, value) => {
        var copy_filter = createDeepClone(filter);
        copy_filter[name] = value;
        dispatch(ticketActions.setTicketFilter(copy_filter, "INS"));
    }

    const onPageChange = (page) => {
        var copy_filter = createDeepClone(filter);
        copy_filter.pageIndex = page;
        dispatch(ticketActions.setTicketFilter(copy_filter, "INS"));
    }

    const showMyTicketsOnly = () => {
        if(user && user.showMyTicketsOnly) {
            return true;
        }

        return false;
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Inspection Ticket Search" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-3">
                                        <Col xxl={(showMyTicketsOnly() ? 8 : 10)} md={12}>
                                            <MySearchOption
                                                placeholder={"Search for inspection tickets..."}
                                                value={filter.search}
                                                searchList={list.searchList || []}
                                                fieldName="search"
                                                onChange={onFilterChange}
                                            />
                                        </Col>
                                        <Col xxl={2} md={12} className={showMyTicketsOnly() ? "" : "d-none"}>
                                            <div className="form-check pt-2 ms-2 me-2">
                                                <input type="checkbox" className="form-check-input"
                                                    checked={filter.mineOnly}
                                                    onChange={e => onFilterChange("mineOnly", e.target.checked)}
                                                /> My Tickets Only
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <button type="button" className="btn btn-primary btn-label w-100" 
                                                disabled={loading}
                                                onClick= {() => doSearch()}
                                            >
                                                <i className="ri-search-eye-line label-icon align-middle"></i> Search
                                            </button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardLoader loading={loading} />
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <Table className="table-hover align-middle mb-0">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th style={{width:10}}>&nbsp;</th>
                                                    <th>Ticket #</th>
                                                    <th>Status</th>
                                                    <th>Inspector Name</th>
                                                    <th>Inspection Start Date</th>
                                                    <th>Inspection End Date</th>
                                                    <th>Action Date</th>
                                                    <th>Date Added</th>
                                                    <th>Added By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.dataList.map(function(item, index){
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline tasks-list-menu mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to={`/request-edit/${item.id}`}>
                                                                                <i className="ri-pencil-fill fs-20 align-bottom text-primary"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {item.requestNumber}
                                                                    {user && !user.isInspector && !user.isInspectorCoordinator &&
                                                                    <Link to={`/request-edit/${item.inspectionParentId}`}>
                                                                        (view cosmetic)
                                                                    </Link>
                                                                    }
                                                                </div>
                                                                
                                                            </td>
                                                            <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                                            <td>{item.inspectionUserName}</td>
                                                            <td><MyDateRenderer value={item.inspectionStartDate} format={"mm/dd/yyyy"} /></td>
                                                            <td><MyDateRenderer value={item.inspectionEndDate} format={"mm/dd/yyyy"} /></td>
                                                            <td><MyDateRenderer value={item.lastActionDate} /></td>
                                                            <td><MyDateRenderer value={item.dateAdded} /></td>
                                                            <td>{item.addedBy}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <PaginationComponent
                                                total={list.totalCount}
                                                currentPage={filter.pageIndex}
                                                onPageChange={page => onPageChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default TicketSearchScreenINS;