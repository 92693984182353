import React from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Card, Col, Container, Input, Label, Row , Button, Spinner} from 'reactstrap';
import AuthSlider from './authCarousel';

import { createDeepClone } from "../utils/index";

import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "../actions/LoginActions";

const  PasswordResetScreen = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.login.loading);
    const fbo = useSelector((state) => state.login.passwordFBO);

    const [fboState, setFBOState] = React.useState(fbo);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    
    React.useEffect(() => {
	    dispatch(loginActions.loadChangePassword(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);

        if(fbo){
            reset(fbo);
        }

        clearErrors();
    }, [fbo]);

    const onChangeResetPassword = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState[name] = value;
        }
        else if(value === false) {
            copy_fboState[name] = false;
        }
        else {
            copy_fboState[name] = null;
        }
        
        setFBOState(copy_fboState);
    }
    
    const handleSaveChangePassword = async () => {
        const valid = await trigger();
        if(valid) {
            dispatch(loginActions.saveChangePassword(fboState, navigate));
        }
    }

    return(
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <h5 className="text-primary mb-3">Create new password</h5>

                                                <div className="p-2">
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email</Label>
                                                        <input type="text" className="form-control" disabled={true} 
                                                            value={(fboState && fboState.email) || ""} 
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <input type={showPassword ? "text" : "password"} placeholder="Enter Password" className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                                                value={(fboState && fboState.password) || ""}
                                                                {...register("password", { required: true })}
                                                                onChange={e => onChangeResetPassword("password", e.target.value)}
                                                            />
                                                            <button type="button" className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" style={{boxShadow:"none"}}
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Confirm Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <input type={showConfirmPassword ? "text" : "password"} placeholder="Confirm Password" className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                                                                value={(fboState && fboState.confirmPassword) || ""}
                                                                {...register("confirmPassword", { required: true })}
                                                                onChange={e => onChangeResetPassword("confirmPassword", e.target.value)}
                                                            />
                                                            <button type="button" className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" style={{boxShadow:"none"}}
                                                                onClick={() => setShowConfirmPassword(!showPassword)}
                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="mt-4">
                                                        <Button color="primary" className="w-100" type="button" disabled={loading} onClick={handleSaveChangePassword}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                            Reset Password
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">Wait, I remember my password... <Link to="/" className="fw-bold text-primary text-decoration-underline"> Click here </Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} Ticketing System. Crafted with <i className="mdi mdi-heart text-danger"></i> by TUV</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

            </div>
        </React.Fragment>
    );
}
  
export default PasswordResetScreen;