import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';

const Navdata = () => {
    const navigate = useNavigate();

    const user = useSelector((state) => state.login.user);

    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
    }, [
        navigate,
        iscurrentState,
        isDashboard,
    ]);

    const menuItems = [];

    menuItems.push({
        id: "dashboard",
        label: "Dashboards",
        icon: "ri-dashboard-2-line",
        link: "/#"
    });

    if(user && user.canAddRequest) {
        if(user.defaultModule === "ISO") {
            menuItems.push({
                id: "CreateRequest",
                label: "Add Ticket",
                icon: "ri-add-circle-line",
                link: "/iso-request-add"
            });
        }
        else {
            menuItems.push({
                id: "CreateRequest",
                label: "Add Ticket",
                icon: "ri-add-circle-line",
                link: "/request-add"
            });
        }
    }

    if(user.defaultModule === "ISO") {
        menuItems.push({
            id: "SearchRequests",
            label: "Tickets",
            icon: "ri-search-line",
            link: "/iso-requests"
        });

        menuItems.push({
            id: "NotifySearchRequests",
            label: "Upcoming Expiry",
            icon: "ri-search-line",
            link: "/iso-request-notifications"
        });
    }
    else {
        
        if(user.isInspector || user.isInspectorCoordinator || user.isSampler) {
            if(user.isInspector || user.isInspectorCoordinator) {
                menuItems.push({
                    id: "SearchInspections",
                    label: "Inspections",
                    icon: "ri-search-line",
                    link: "/ins-requests"
                });
            }

            if(user.isSampler) {
                menuItems.push({
                    id: "SearchSamplings",
                    label: "Samplings",
                    icon: "ri-search-line",
                    link: "/samp-requests"
                });
            }
        }
        else {
            menuItems.push({
                id: "SearchRequests",
                label: "Tickets",
                icon: "ri-search-line",
                link: "/requests"
            });
        
            menuItems.push({
                id: "SearchReviews",
                label: "Reviews",
                icon: "ri-search-line",
                link: "/revw-requests"
            });
        
            menuItems.push({
                id: "SearchShipmentCertificates",
                label: "Shipment Certificates",
                icon: "ri-search-line",
                link: "/scoc-requests"
            });
        
            menuItems.push({
                id: "SearchProductCertificates",
                label: "Product Certificates",
                icon: "ri-search-line",
                link: "/pcoc-requests"
            });
        
            menuItems.push({
                id: "SearchFactoryAudit",
                label: "Factory Audits",
                icon: "ri-search-line",
                link: "/fa-requests"
            });
        
            menuItems.push({
                id: "SearchCosmetic",
                label: "Cosmetics",
                icon: "ri-search-line",
                link: "/cos-requests"
            });
    
            menuItems.push({
                id: "SearchInspections",
                label: "Inspections",
                icon: "ri-search-line",
                link: "/ins-requests"
            });

            menuItems.push({
                id: "SearchSamplings",
                label: "Samplings",
                icon: "ri-search-line",
                link: "/samp-requests"
            });
    
            menuItems.push({
                id: "NotifySearchRequests",
                label: "Upcoming Expiry",
                icon: "ri-search-line",
                link: "/request-notifications"
            });            
        }

        // menuItems.push({
        //     id: "SearchRequestDocuments",
        //     label: "Ticket Documents",
        //     icon: "ri-file-search-line",
        //     link: "/documents"
        // });
    }

    if(user && user.isLabReportData && user.defaultModule === "Ticketing") {
        menuItems.push({
            id: "LabReport",
            label: "Lab Reports",
            icon: "ri-search-eye-line",
            link: "/lab-reports"
        });
    }

    if(user && (
        user.canManageCompany 
        || user.canManageUser 
        || user.canManageGroup 
        || user.canManageCustomer 
        || user.canManageImporter 
        || user.canManageExporter 
        || user.canManageManufacturer 
        || user.canManageProduct)) {
        menuItems.push({
            label: "Configurations",
            isHeader: true,
        });
    }

    if(user && user.canManageCompany) {
        menuItems.push({
            id: "CompanySearch",
            label: "Companies",
            icon: "ri-apps-2-line",
            link: "/companies"
        });
    }

    if(user && user.canManageUser) {
        menuItems.push({
            id: "UserSearch",
            label: "Users",
            icon: "ri-apps-2-line",
            link: "/users"
        });
    }

    if(user && user.canManageUser && user.defaultModule === "ISO") {
        menuItems.push({
            id: "AuditorSearch",
            label: "Auditors",
            icon: "ri-apps-2-line",
            link: "/auditors"
        });
    }

    if(user && user.canManageUser && user.defaultModule === "Ticketing") {
        menuItems.push({
            id: "InspectorSearch",
            label: "Inspectors",
            icon: "ri-apps-2-line",
            link: "/inspectors"
        });
    }

    if(user && user.canManageGroup && user.defaultModule === "Ticketing") {
        menuItems.push({
            id: "GroupSearch",
            label: "Groups",
            icon: "ri-apps-2-line",
            link: "/groups"
        });
    }

    if(user && user.canManageCustomer) {
        menuItems.push({
            id: "CustomerSearch",
            label: "Customers",
            icon: "ri-apps-2-line",
            link: "/customers"
        });
    }

    if(user && user.canManageImporter) {
        menuItems.push({
            id: "ImporterSearch",
            label: "Importers",
            icon: "ri-apps-2-line",
            link: "/vendor-search/importer"
        });
    }

    if(user && user.canManageExporter) {
        menuItems.push({
            id: "ExporterSearch",
            label: "Exporters",
            icon: "ri-apps-2-line",
            link: "/vendor-search/exporter"
        });
    }
    
    if(user && user.canManageManufacturer) {
        menuItems.push({
            id: "ManufacturerSearch",
            label: "Manufacturers",
            icon: "ri-apps-2-line",
            link: "/vendor-search/manufacturer"
        });
    }
    
    if(user && user.canManageProduct) {
        menuItems.push({
            id: "ProductSearch",
            label: "Products",
            icon: "ri-apps-2-line",
            link: "/products"
        });
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;