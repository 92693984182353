import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, Progress } from 'reactstrap';

import { createDeepClone } from "../utils/index";
import ConfirmModal from "./ConfirmModal";
import PdfModal from "./PdfModal";
import CardLoader from "./CardLoader";
import { api, getFileTitle, getFileExtension } from "../utils/index";

import logoPdf from "../assets/images/pdf.png";
import logoZip from "../assets/images/zip.png";

import { useDispatch, useSelector } from "react-redux";
import * as documentActions from "../actions/DocumentActions";
import * as commonActions from "../actions/CommonActions";

const TicketProductPhotoGrid = (props) => {
    const dispatch = useDispatch();

    const downloading = useSelector((state) => state.document.downloading);

    const [showModal, setShowModal] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);

    const [deleteId, setDeleteId] = React.useState(0);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
        
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentUploadProgress, setCurrentUploadProgress] = useState(false);
    
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [fboState, setFBOState] = React.useState(null);
    const [documentList, setDocumentList] = React.useState([]);
    const [gridCollpased, setGridCollapsed] = React.useState(true);
    
    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
        
            var data = await documentActions.loadProductPhoto(props.refId, props.refName, props.parentId, props.parentName);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);
            }
    
            setLoading(false);
        }
        
        fetchData();
    }, [props.refId, props.refName, props.parentId, props.parentName]);

    const onFileChange = (event) => {
        if(event.target.files.length > 0) {
            var files = [...event.target.files];
            var copy_selectedFiles = createDeepClone(selectedFiles);
            
            files.map(function(item, index) {
                if(item.type.match(/image-*/) || item.type.match(/pdf/) || item.type.match(/compressed/) || item.type.match(/rar/) || item.type.match(/zip/) || item.type.match(/tar/)) {
                    copy_selectedFiles.push({
                        file: item, 
                        name: item.name, 
                        size: item.size, 
                        title: getFileTitle(item),
                        titleValid: true,
                        uploaded: false,
                        progress: 0, 
                        error: ""
                    });
                }
            });

            setSelectedFiles(copy_selectedFiles);
        }
    }

    const onFileTitleChange = (index, value) => {
        var copy_selectedFiles = createDeepClone(selectedFiles);
        if(value) {
            copy_selectedFiles[index].title = value;
            copy_selectedFiles[index].titleValid = true;
        }
        else {
            copy_selectedFiles[index].title = null;
            copy_selectedFiles[index].titleValid = false;
        }
        
        setSelectedFiles(copy_selectedFiles);
    }

    const onFileDelete = (index) => {
        var copy_selectedFiles = createDeepClone(selectedFiles);
        copy_selectedFiles.splice(index, 1);
        setSelectedFiles(copy_selectedFiles);
    }

    const showModalDialog = () => {
        setShowModal(true);
        setSelectedFiles([]);
    }

    const hideModalDialog = () => {
        setShowModal(false);
    }

    const showPhotoModalDialog = (item) => {
        setSelectedItem(item);
        setShowPhotoModal(true);
    }

    const hidePhotoModalDialog = () => {
        setShowPhotoModal(false);
    }

    const showPdfModalDialog = (item) => {
        setSelectedItem(item);
        setShowPdfModal(true);
    }

    const hidePdfModalDialog = () => {
        setShowPdfModal(false);
    }

    const uploadFile = async (file, index) => {
        setSaving(true);
        setCurrentUploadProgress(0);
        
        var uploadSuccess = true;
        var uploadError = "";
        var tempFileName = "";
        var chunkSize = 1048576 * 5;
        var chunkCounter = 0;
        var chunkTotal = file.size % chunkSize == 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;
        var chunkStart = 0;
        var chunkEnd = chunkSize;

        selectedFiles[index].uploaded = false;
        selectedFiles[index].progress = 0;
        selectedFiles[index].error = "";

        while (chunkCounter <= chunkTotal && uploadSuccess === true) {
            var chunk = file.file.slice(chunkStart, chunkEnd);
                        
            const uploadFormData = new FormData();
            uploadFormData.append("tempFileName", tempFileName);
            uploadFormData.append("file", chunk, file.name);

            var data = await documentActions.uploadFileChunk(uploadFormData);
            if(data.success) {
                tempFileName = data.tempFileName;
                uploadSuccess = true;
                uploadError = "";

                var percentage = Math.round((chunkCounter / chunkTotal) * 100);
                selectedFiles[index].progress = percentage;

                setCurrentUploadProgress(percentage);
            }
            else {
                uploadSuccess = false;
                uploadError = data.error;
            }

            chunkCounter += 1;
            chunkStart = chunkEnd;
            chunkEnd += chunkSize;
        }

        if(uploadSuccess) {
            const formData = new FormData();
                            
            formData.append("document", JSON.stringify(fboState));
            formData.append("title", file.title ? file.title : file.name);
            formData.append("originalFileName", file.name);
            formData.append("tempFileName", tempFileName);

            var data = await documentActions.saveProductPhoto(formData);
            if(data.error) {
                uploadSuccess = false;
                uploadError = data.error;
            }
            else {
                uploadSuccess = true;
                uploadError = "";

                setDocumentList(data.list);

                if(props.onChange) {
                    props.onChange(props.isProduct);
                }
            }
        }

        selectedFiles[index].uploaded = uploadSuccess;
        selectedFiles[index].error = uploadError;
        
        setSelectedFiles(selectedFiles);
        setSaving(false);
    }

    const onSaveDocumentClick = async () => {
        if(selectedFiles && selectedFiles.length > 0) {
            var valid = true;
            
            selectedFiles.map(function(item, index){
                if(!item.titleValid) {
                    valid = false;
                }
            });

            if(valid) {
                await SaveDocument();
            }
        }
        else {
            dispatch(commonActions.setErrorMessage("No file is selected."));
        }
    }

    const SaveDocument = async () => {
        var allFilesUploaded = true;
        var closeDialog = true;

        for(var index in selectedFiles) {
            if(selectedFiles[index].uploaded === false) {
                allFilesUploaded = false;
                await uploadFile(selectedFiles[index], index);
            }
        }

        for(var index in selectedFiles) {
            if(selectedFiles[index].uploaded === false) {
                closeDialog = false;
            }
        }

        if(closeDialog) {
            hideModalDialog();
            setSelectedFiles([]);

            dispatch(commonActions.setSuccessMessage("Product Photo(s) Added Successfully!"))
        }
        else if(allFilesUploaded) {
            dispatch(commonActions.setErrorMessage("All Selected photos are uploaded."));
        }
    }

    const onDeleteClick = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const onDeleteConfirm = async () => {
        setShowDeleteModal(false);

        if(deleteId > 0) {
            setLoading(true);
        
            var data = await documentActions.deleteProductPhoto(deleteId, props.refId, props.refName, props.parentId, props.parentName);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);

                if(props.onChange) {
                    props.onChange(true);
                }

                dispatch(commonActions.setSuccessMessage("Product Photo Delete Successfully!"))
            }
    
            setLoading(false);
        }
    }

    const onDownloadDocument = (id, documentName) => {
        dispatch(documentActions.downloadDocument(id, documentName));
    };

    const isPdfFile = (fileName) => {
        var pdfList = ["pdf"];
        var extension = getFileExtension(fileName);

        if(pdfList.indexOf(extension.toLowerCase()) !== -1) {
            return true;
        }

        return false;
    }

    const isZipFile = (fileName) => {
        var pdfList = ["rar", "zip", "7z", "tar", "tgz", "arj", "arc", "gz", "hqx", "sit", "bzip2", "cabinet", "binhex"];
        var extension = getFileExtension(fileName);

        if(pdfList.indexOf(extension.toLowerCase()) !== -1) {
            return true;
        }

        return false;
    }

    return (
        <Fragment>
            <Card className="mb-3">
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading || downloading} />
                    <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setGridCollapsed(!gridCollpased)}>
                        {gridCollpased && <i className="ri-add-box-line align-bottom text-primary"></i>}
                        {!gridCollpased && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                        Product Photos ({gridCollpased ? "Expand" : "Collapse"})
                    </h5>
                    {props.canChange && !gridCollpased && 
                    <div className="flex-shrink-0">
                        <div className="d-flex flex-wrap gap-2">
                            <button className="btn btn-primary btn-label"
                                onClick={() => showModalDialog()}
                            >
                                <i className="ri-add-line label-icon align-bottom"></i> Add Product Photo
                            </button>
                        </div>
                    </div>
                    }
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                    <Collapse isOpen={!gridCollpased} className="collapse">
                        <Row className="gy-2">
                            {documentList.length == 0 && <div>No Photos Uploaded</div>}
                            {documentList.filter(x => isPdfFile(x.name) === false && isZipFile(x.name) === false).map(function(item, index){
                                return(
                                    <Fragment key={index}>
                                        <Col xxl={4} md={6}>
                                            <Card>
                                                <CardHeader style={{padding:7}}>
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill float-end fs-11" title="View Photo"
                                                        onClick={() => showPhotoModalDialog(item)}
                                                    >
                                                        <i className="ri-fullscreen-line"></i>
                                                    </button>
                                                    {props.canChange && 
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill float-end fs-11 me-1" title="Delete Photo"
                                                        onClick={() => onDeleteClick(item.id)}
                                                    >
                                                        <i className="ri-delete-bin-2-line" />
                                                    </button>
                                                    }
                                                    <h6 className="card-title mb-0" style={{padding:7}}>{item.title}</h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "300px", cursor: "pointer"}}
                                                        src={`${api}Document/DownloadDocument?id=${item.id}`} alt="Not Found" title={item.title}
                                                        onClick={() => showPhotoModalDialog(item)}  
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Fragment>
                                );
                            })}
                            <Col xxl={12} md={12} className="p-0 m-0">&nbsp;</Col>
                            {documentList.filter(x => isPdfFile(x.name) === true).map(function(item, index){
                                return(
                                    <Fragment key={index}>
                                        <Col xxl={4} md={6}>
                                            <Card>
                                                <CardHeader style={{padding:7}}>
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill float-end fs-11" title="Download Pdf"
                                                        onClick={() => onDownloadDocument(item.id, item.name)}
                                                    >
                                                        <i className="ri-download-line"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill float-end fs-11 me-1" title="View Pdf"
                                                        onClick={() => showPdfModalDialog(item)}
                                                    >
                                                        <i className="ri-fullscreen-line"></i>
                                                    </button>
                                                    {props.canChange && 
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill float-end fs-11 me-1" title="Delete Pdf"
                                                        onClick={() => onDeleteClick(item.id)}
                                                    >
                                                        <i className="ri-delete-bin-2-line" />
                                                    </button>
                                                    }
                                                    <h6 className="card-title mb-0" style={{padding:7}}>{item.title}</h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <img src={logoPdf} alt="" height="50" title={item.title} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Fragment>
                                );
                            })}
                            <Col xxl={12} md={12} className="p-0 m-0">&nbsp;</Col>
                            {documentList.filter(x => isZipFile(x.name) === true).map(function(item, index){
                                return(
                                    <Fragment key={index}>
                                        <Col xxl={4} md={6}>
                                            <Card>
                                                <CardHeader style={{padding:7}}>
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill float-end fs-11" title="Download Zip"
                                                        onClick={() => onDownloadDocument(item.id, item.name)}
                                                    >
                                                        <i className="ri-download-line"></i>
                                                    </button>
                                                    {props.canChange && 
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill float-end fs-11 me-1" title="Delete Zip"
                                                        onClick={() => onDeleteClick(item.id)}
                                                    >
                                                        <i className="ri-delete-bin-2-line" />
                                                    </button>
                                                    }
                                                    <h6 className="card-title mb-0" style={{padding:7}}>{item.title}</h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <img src={logoZip} alt="" height="50" title={item.title} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Fragment>
                                );
                            })}
                        </Row>
                    </Collapse>
                </CardBody>
            </Card>

            <Modal tabIndex="-1" size="xl" isOpen={showModal} toggle={hideModalDialog} centered={true}>
                <ModalHeader>Upload Product Photos</ModalHeader>
                <ModalBody>
                    <CardLoader loading={saving} />
                    <Row className="gy-2">
                        <Col xxl={12} md={12}>
                            <label className="btn btn-primary btn-label mb-0 float-end">
                                <i className="ri-check-double-line label-icon align-bottom"></i> Choose File(s)
                                <input className="d-none" type="file" accept="image/*,.pdf" onChange={onFileChange} multiple={true} />
                            </label>
                        </Col>
                        <Col xxl={12} md={12}>
                            <Table className="table-hover table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>Title</th>
                                        <th>File Name</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFiles.length === 0 && <tr><td colSpan={3}>No file(s) Selected</td></tr>}
                                    {selectedFiles.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>
                                                    <div className="col-sm-12">
                                                    <input type="text" className={`form-control ${item.titleValid ? "" : "is-invalid"}`} readOnly={item.uploaded}
                                                            value={item.title}
                                                            onChange={e => onFileTitleChange(index, e.target.value)}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{verticalAlign:"middle"}}>
                                                    <div className="col-sm-12">
                                                        <strong>{item.name}</strong>
                                                    </div>
                                                    {item.error && <Progress color="danger" style={{height:"25px"}} value={100}> {item.error} </Progress>}
                                                    {!item.error && <Progress color="primary" style={{height:"25px"}} value={item.uploaded ? 100 : item.progress}> {item.uploaded ? 100 : item.progress}% </Progress>}
                                                </td>
                                                <td className="text-center">
                                                    {!item.uploaded &&
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill ms-1" title="Remove Photo"
                                                        onClick={() => onFileDelete(index)}
                                                    >
                                                        <i className="ri-delete-bin-2-line"></i>
                                                    </button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-label" onClick={onSaveDocumentClick} disabled={loading || saving}>
                        <i className="ri-save-line label-icon align-bottom"></i> Save File(s)
                    </button>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hideModalDialog} disabled={loading || saving}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal tabIndex="-1" size="xl" isOpen={showPhotoModal} toggle={hidePhotoModalDialog} centered={true}>
                <ModalHeader>{selectedItem && selectedItem.title}</ModalHeader>
                <ModalBody>
                    {selectedItem && <img style={{maxWidth:"800px", maxHeight: "600px"}} src={`${api}Document/DownloadDocument?id=${selectedItem.id}`} alt="Not Found" />}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hidePhotoModalDialog}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <PdfModal item={selectedItem} show={showPdfModal} onCloseClick={hidePdfModalDialog} />
            <ConfirmModal message={"Are you sure you want to delete the Photo?"} show={showDeleteModal} onCloseClick={() => setShowDeleteModal(false)} onYesClick={onDeleteConfirm} />
        </Fragment>
    );
};

export default TicketProductPhotoGrid;