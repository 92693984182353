import React from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Card, Col, Container, Input, Label, Row , Button, Spinner} from 'reactstrap';
import AuthSlider from './authCarousel';

import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "../actions/LoginActions";

const  LoginScreen = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.login.loading);
    
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    const handleLogin = async () => {
        const valid = await trigger();
        if(valid) {
            dispatch(loginActions.loadLogin(email, password));
        }
    }

    return(
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p className="text-muted">Sign in to continue to Ticketing System.</p>
                                                </div>

                                                <div className="mt-4">
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email</Label>
                                                        <input type="text" placeholder="Enter Email" className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                            value={email}
                                                            {...register("email", { required: true })}
                                                            onChange={e => setEmail(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="mb-1">
                                                        <Label className="form-label" htmlFor="password-input">Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <input type={showPassword ? "text" : "password"} placeholder="Enter Password" className={`form-control pe-5 password-input ${errors.password ? "is-invalid" : ""}`}
                                                                value={password}
                                                                {...register("password", { required: true })}
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                            <button type="button" className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" style={{boxShadow:"none"}}
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="float-end mb-3">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>

                                                    {/* <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                        <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                    </div> */}
                                                    
                                                    <div className="mt-4">
                                                        <Button color="primary" className="w-100" type="button" disabled={loading} onClick={handleLogin}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                            Sign In
                                                        </Button>
                                                    </div>

                                                    {/* <div className="mt-4 text-center">
                                                        <div className="signin-other-title">
                                                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                        </div>

                                                        <div>
                                                            <Button color="primary" className="btn-icon me-1"><i className="ri-facebook-fill fs-16"></i></Button>
                                                            <Button color="danger" className="btn-icon me-1"><i className="ri-google-fill fs-16"></i></Button>
                                                            <Button color="dark" className="btn-icon me-1"><i className="ri-github-fill fs-16"></i></Button>
                                                            <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                        </div>
                                                    </div> */}
                                                </div>

                                                {/* <div className="mt-5 text-center">
                                                    <p className="mb-0">Don't have an account ? <a href="/auth-signup-cover" className="fw-semibold text-primary text-decoration-underline"> Signup</a> </p>
                                                </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} Ticketing System. Crafted with <i className="mdi mdi-heart text-danger"></i> by TUV</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

            </div>
        </React.Fragment>
    );
}
  
export default LoginScreen;