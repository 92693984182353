import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Spinner, Button } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import PaginationComponent from "../components/PaginationComponent";
import MySearchOption from "../components/MySearchOption";
import MyDateRenderer from "../components/MyDateRenderer";
import ComplianceLabel from "../components/ComplianceLabel";
import CustomerCategoryLabel from "../components/CustomerCategoryLabel";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";

const TicketSearchScreen  = () => {
    const dispatch = useDispatch();
        
    const loading = useSelector((state) => state.ticket.loading);
    const list = useSelector((state) => state.ticket.list);
    const filter = useSelector((state) => state.ticket.filter);
    const user = useSelector((state) => state.login.user);

    const doSearch = () => {
        var copy_filter = createDeepClone(filter);
        
        if(copy_filter.pageIndex == 1) {
            dispatch(ticketActions.loadTicketSearch(filter));
        }
        else {
            copy_filter.pageIndex = 1;
            dispatch(ticketActions.setTicketFilter(copy_filter));
        }
    }

    React.useEffect(() => {
		dispatch(ticketActions.loadTicketSearch(filter));
    }, [filter.pageIndex]);

    const onFilterChange = (name, value) => {
        var copy_filter = createDeepClone(filter);
        copy_filter[name] = value;
        dispatch(ticketActions.setTicketFilter(copy_filter));
    }

    const onPageChange = (page) => {
        var copy_filter = createDeepClone(filter);
        copy_filter.pageIndex = page;
        dispatch(ticketActions.setTicketFilter(copy_filter));
    }

    const showMyTicketsOnly = () => {
        if(user && user.showMyTicketsOnly) {
            return true;
        }

        return false;
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Ticket Search" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-3">
                                        <Col xxl={user && user.canAddRequest ? (showMyTicketsOnly() ? 6 : 8) : (showMyTicketsOnly() ? 8 : 10)} md={12}>
                                            <MySearchOption
                                                placeholder={"Search for tickets..."}
                                                value={filter.search}
                                                searchList={list.searchList || []}
                                                fieldName="search"
                                                onChange={onFilterChange}
                                            />
                                        </Col>
                                        <Col xxl={2} md={12} className={showMyTicketsOnly() ? "" : "d-none"}>
                                            <div className="form-check pt-2 ms-2 me-2">
                                                <input type="checkbox" className="form-check-input"
                                                    checked={filter.mineOnly}
                                                    onChange={e => onFilterChange("mineOnly", e.target.checked)}
                                                /> My Tickets Only
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <button type="button" className="btn btn-primary btn-label w-100" 
                                                disabled={loading}
                                                onClick= {() => doSearch()}
                                            >
                                                <i className="ri-search-eye-line label-icon align-middle"></i> Search
                                            </button>
                                        </Col>
                                        {user && user.canAddRequest &&
                                        <Col xxl={2} md={12}>
                                            <Link to={`/request-add`} className="btn btn-primary btn-label w-100">
                                                <i className="ri-add-line label-icon align-middle"></i> Add Ticket
                                            </Link>
                                        </Col>
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardLoader loading={loading} />
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <Table className="table-hover align-middle mb-0">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th style={{width:10}}>&nbsp;</th>
                                                    <th>Ticket #</th>
                                                    <th>Status</th>
                                                    <th>Ticket Type</th>
                                                    <th>Customer Name</th>
                                                    <th>CRM Invoice #</th>
                                                    <th>Tax Invoice #</th>
                                                    <th>SASO Request #</th>
                                                    <th>Faseh Request #</th>
                                                    <th>Action Date</th>
                                                    <th>Date Added</th>
                                                    <th>Added By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.dataList.map(function(item, index){
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline tasks-list-menu mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to={`/request-edit/${item.id}`}>
                                                                                <i className="ri-pencil-fill fs-20 align-bottom text-primary"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {item.requestNumber}
                                                                    <ComplianceLabel value={item.complianceState || ""} />
                                                                </div>
                                                                {item.ticketType === "CONTAINER" &&
                                                                <span className="badge bg-success">Container</span>
                                                                }
                                                            </td>
                                                            <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                                            <td><span className="badge" style={{backgroundColor: item.serviceColorCode}}>{item.serviceName}</span></td>
                                                            <td>
                                                                {item.customerName}
                                                                <CustomerCategoryLabel value={item.customerCategory || ""} />
                                                            </td>
                                                            <td>{item.crmInvoiceNumber}</td>
                                                            <td>{item.taxInvoiceNumber}</td>
                                                            <td>{item.sasoRequestNumber}</td>
                                                            <td>{item.fasehRequestNumber}</td>
                                                            <td><MyDateRenderer value={item.lastActionDate} /></td>
                                                            <td><MyDateRenderer value={item.dateAdded} /></td>
                                                            <td>{item.addedBy}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <PaginationComponent
                                                total={list.totalCount}
                                                currentPage={filter.pageIndex}
                                                onPageChange={page => onPageChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default TicketSearchScreen;